@import '../../../styles/customMediaQueries.css';

.root {
  composes: marketplaceModalFormRootStyles from global;

  justify-content: flex-start;

  @media (--viewportMedium) {
    justify-content: space-between;
  }
}

.name {
  display: flex;
  justify-content: space-between;
  margin-top: 24px;

  @media (--viewportMedium) {
    margin-top: 32px;
  }
}

.firstNameRoot {
  width: calc(34% - 9px);
}

.lastNameRoot {
  width: calc(66% - 9px);
}

.password {
  composes: marketplaceModalPasswordMargins from global;
}

.bottomWrapper {
  composes: marketplaceModalBottomWrapper from global;
}

.fieldMargins {
  margin-top: 24px;

  @media (--viewportMedium) {
    margin-top: 32px;
  }
}

.hiddenUserInput {
  display: none;
}

.visibleUserInput {
  display: unset;
}
.userTypeHeading {
  text-align: left;
  font-size: 14px;
  font-weight: 600;
  width: 100%;
  margin: 0;
}
